import React from "react";
import "./App.css";
import { Navbar, Nav } from "react-bootstrap";


function NavBar(){
    return (
        <div className="row bg-secondary bg-gradient px-5">
        <Navbar style={{ color: "black" }} collapseOnSelect expand="lg">
          <Navbar.Brand href="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-house-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z" />
              <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z" />
            </svg>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/pick-popular">Pick Popular</Nav.Link>
              <Nav.Link href="/use-my-list">Use My List</Nav.Link>
              <Nav.Link href="/find-my-dinner">Find My Dinner</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
}

export default NavBar